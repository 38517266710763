import { Hero } from "@components/Hero/Hero";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";

const IntegrationsHero = () => {
	const info = {
		title: "Seamless research workflows",
		description:
			"Adaptable integration options allow you to meet your own unique research needs.",
		image: (
			<StaticImage
				loading="eager"
				src="../_assets/integration-hero.png"
				alt="Genemod Equipment Hero Image"
			/>
		),
	};
	return <Hero {...info} generalSansFont />;
};

export default IntegrationsHero;
